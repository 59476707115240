
import '../../App.css';
import React from 'react';

export default () => {
    return (
        
      <>
        <section class = "plotsDetailSection3">
            <div class = "container">
                <div class="section-title">
                    <h2>Villa Vs Apartment 
                    <div class="villaIcon">
                    <img src={require('../../images/icons/villa.svg')} alt="questionImg" /> 
                    </div>            
                    </h2>
                </div> 
                <div class="row">                    
                    <div class="col-sm-6">
                        <div class="costOfVilla">
                        <p>COST OF A VILLA</p>
                            <table class="table">
  
                                <tbody>
                                    <tr>
                                    <th scope="row"></th>
                                    <td>Land Cost :</td>
                                    <td>30*40 plot</td>
                                    <td>3500000</td>
                                    </tr>
                                    <tr>
                                    <th scope="row"></th>
                                    <td>Construction Cost :</td>
                                    <td>(Rs.2000*1800)</td>
                                    <td>3600000</td>
                                    </tr>
                                    <tr>
                                    <th scope="row"></th>
                                    <td><h5>Total Cost</h5></td>
                                    <td></td>
                                    <td><h5>7100000</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="costOfApartment">
                        <p>COST OF AN APARTMENT</p>
                            <table class="table">
  
                                <tbody>
                                    <tr>
                                    <th scope="row"></th>
                                    <td><h5>3 bhk- 1500 sqft-</h5></td>
                                    <td></td>
                                    <td><h5>69 lakhs</h5></td>
                                    </tr>
                                    <tr>
                                    <th scope="row"></th>
                                    <td><h5>3 bhk- 1635 sqft-</h5></td>
                                    <td></td>
                                    <td><h5>75 lakhs</h5></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                     </div>
                     <div class="col-sm-6">
                        <img src={require('../../images/pdSection3.png')} alt="questionImg" />
                        <div class = "pd-s2-contents">
                            <img src={require('../../images/icons/eye.svg')} alt="questionImg" />
                            <h1>Overview<br></br><span>Buy a plot & build a villa still at the price equivalent of an apartment. The cost of an apartment from a competitor project is Rs. 4600/- per sqft, while with the above calculations, the cost of the villa would be closer to Rs. 4000/- per sqft! All this while offering you the luxury of building a villa of your choice, ownership of land, and more importantly, complete independence.</span></h1>    
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
      </>
    );
  }
  

