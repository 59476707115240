import React from 'react'
import '../../App.css';

export default  () => {
   
    return (
        <>
        <section class ="blogSection2">
        <div class="container">
                    <div class="row">    
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="blogBox">
                            <p>About Smart Plot</p>
                            <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. </span>
                            </div>
                            <div class="blogBox-top">
                                
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="blogBox">
                            <p>About Smart Plot</p>
                            <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. </span>
                            </div>
                            <div class="blogBox-top">
                                
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="blogBox">
                            <p>About Smart Plot</p>
                            <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. </span>
                            </div>
                            <div class="blogBox-top">
                                
                            </div>
                        </div>
                    </div>
                </div>
                </section>
        
        </>
      );
}