import React from 'react';
import '../../App.css';
import BlogCards from '../blog/BlogCards';
import BlogCarousel from '../blog/BlogCarousel';
import Footer from '../home/Footer';

export default function Home() {
  return (
    <>
      
      <BlogCarousel />
      <BlogCards />
      <Footer />
     
      
      
    </>
  );
}

