
import '../../App.css';
import React from 'react';

export default () => {
    return (
        
      <>
        <section class = "masterPlan">
            <div class = "container">
                <div class ="masterPlanBg">
                    <div class="section-title">
                        <h2>Master Plan
                            <div class="masterPlanIcon">
                                <img src={require('../../images/icons/masterPlan.svg')} alt="questionImg" /> 
                            </div>            
                        </h2>
                    </div> 
                    <img src={require('../../images/master-plan.png')} alt="questionImg" /> 
                    <button type="submit" class="btn btn-primary" >View Plan</button>

                </div>
                
            </div>
        </section>
        
      </>
    );
  }
  

