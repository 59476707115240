
import '../../App.css';
import React from 'react';

export default () => {
    return (
        
      <>
        <section class = "plotsDetailSection4">
            <div class="section-title">
                <h2>Photos</h2>
            </div>
            <div class="photoGrid">
            <div class = "row">
                <div class = "col-sm-6">
                    <div class = "container">
                        <img src={require('../../images/gallery-1.png')} alt="questionImg" width={600} height={560}/>
                    </div>
                </div>
                <div class = "col-sm-6">
                    <div class = "container">
                        <img src={require('../../images/gallery-2.png')} alt="questionImg" width={600}/>
                    </div>
                    <div class="spacer7"></div>
                    <div class = "row">
                        <div class = "col-sm-6">
                            <div class = "container">
                                <img src={require('../../images/gallery-3.png')} alt="questionImg" width={300}/>
    
                            </div>
                        
                        </div>
                        <div class = "col-sm-5">
                            <div class = "container">
                                <img src={require('../../images/gallery-4.png')} alt="questionImg" width={290} height={253}/>
    
                            </div>
                        
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </section>
        
      </>
    );
  }
  

