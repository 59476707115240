import React from 'react';
import '../../App.css';
import MainBanner from '../about-us/MainBanner';
import AboutSmartplot from '../about-us/AboutSmartplot';
import Footer from '../home/Footer';


export default function AboutUs() {
  return (
    <>
      
      <MainBanner />
      <AboutSmartplot />
      <Footer />
      
      
      
    </>
  );
}

