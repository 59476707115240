import React from "react";
import Carousel from "react-elastic-carousel";
//import Item from "../home/Item";

export default  () => {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 },
      ];
    return (
        <>
        <section class="blogCarousel">
            <div class="section-title">
                <h2>Blog Page</h2>
            </div>
            <div class ="row">
            <div class ="col-sm-5">
                <div class="blogImage">
                    <img src={require('../../images/blogSection1.png')} alt="plot2Img" />
                </div>
            </div>
            <div class ="col-sm-7">
            <div className="carousel">
                <Carousel breakPoints={breakPoints}>
                <div class="blogItem">
                    <div class="blogCarouselImage">
                        <img src={require('../../images/blog-1.png')} alt="plot2Img" />
                    </div>
                    <p>Date: 01/05-2021</p>
                    <h1>About Smart Plot</h1>
                    <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. Investing in land in an underdeveloped/early phase, then waiting for some time, has been rewarding to investors.</span>
                    
                </div>
                <div class="blogItem">
                    <div class="blogCarouselImage">
                        <img src={require('../../images/blog-1.png')} alt="plot2Img" />
                    </div>
                    <p>Date: 01/05-2021</p>
                    <h1>About Smart Plot</h1>
                    <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. Investing in land in an underdeveloped/early phase, then waiting for some time, has been rewarding to investors.</span>
                    
                </div>
                <div class="blogItem">
                    <div class="blogCarouselImage">
                        <img src={require('../../images/blog-1.png')} alt="plot2Img" />
                    </div>
                    <p>Date: 01/05-2021</p>
                    <h1>About Smart Plot</h1>
                    <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. Investing in land in an underdeveloped/early phase, then waiting for some time, has been rewarding to investors.</span>
                    
                </div>
                <div class="blogItem">
                    <div class="blogCarouselImage">
                        <img src={require('../../images/blog-1.png')} alt="plot2Img" />
                    </div>
                    <p>Date: 01/05-2021</p>
                    <h1>About Smart Plot</h1>
                    <span>Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. Investing in land in an underdeveloped/early phase, then waiting for some time, has been rewarding to investors.</span>
                    
                </div>
                
                </Carousel>
            </div>
          </div>
          </div>
          </section>
        </>
      );
}