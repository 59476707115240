import React from 'react';
import '../../App.css';
import '../Button.css';
//import { Link } from 'react-router-dom';
import WhyInvestingInLand from '../../components/home/WhyInvestingInLand.js';
import MainBanner from '../home/MainBanner';
import WhySmartplot from '../home/WhySmartplot';
import 'bootstrap/dist/css/bootstrap.css';
import OurApproach from '../home/OurApproach';
import HowItWorks from '../home/HowItWorks';
import Newsletter from '../home/Newsletter';
import Footer from '../home/Footer';
import CarouselComponent from '../home/CarouselComponent';

export default function Home() {
  return (
    <>
      
      <MainBanner />
      <WhySmartplot />
      <WhyInvestingInLand />
      <OurApproach />
      <HowItWorks />
      <CarouselComponent />
      <Newsletter />
      <Footer />
     
      
      
    </>
  );
}
