import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
      
      <section class="aboutUs">
        <div class="container">

          <div class="section-title">
            <h2>About Smart Plot</h2>
            <p>
            Real estate is one of the oldest forms of investment. The land has been widely known as a legacy investment passed on to generations with perceived low risk and safe investment. Investing in land in an underdeveloped/early phase, then waiting for some time, has been rewarding to investors.
            </p>
          </div>
          <div class="container">
            
            <div class="row">    
                <div class="col-6 col-md-4">
                    <span><img src={require('../../images/globe.svg')} alt="globeImg" /> www.smartplots.com</span>
                </div>
                <div class="col-12 col-md-4">
                <span><img src={require('../../images/location.svg')} alt="globeImg" /> <br></br><br></br>Address of the Smart Plot</span>
                <div class="locationBg"><img src={require('../../images/aboutSection2.png')} alt="questionImg" /></div>
                
                </div>
                <div class="col-6 col-md-4">
                <span><img src={require('../../images/phone.svg')} alt="globeImg" /> +91-00558899</span>
                </div>
              </div>
          </div>
        </div>
    </section>
        
        
      </>
    );
  }
  

