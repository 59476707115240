import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

export function Button() {
  return (
    <Link to='submit-plot'>
      <button className='btn'>Submit Plot</button>
    </Link>
  );
}
