
import '../../App.css';
import React, { useState } from 'react';
import FAQ from '../plots/FaqFunction';

export default () => {
    
        const [faqs, setfaqs] = useState([
          {
            question: 'Q.  What is the FAR permissible in this property?',
            answer: 'None. We don\'t address hardware issues.',
            open: false
          },
          {
            question: 'Q.  Is there a scope of future expansion?',
            answer: 'You. The Viewer.',
            open: false
          },
          {
            question: 'Q.  What is the FAR permissible in this property?',
            answer: 'None. We don\'t address hardware issues.',
            open: false
          },
          {
            question: 'Q.  Is there a scope of future expansion?',
            answer: 'You. The Viewer.',
            open: false
          }
        ]);
      
        const toggleFAQ = index => {
          setfaqs(faqs.map((faq, i) => {
            if (i === index) {
              faq.open = !faq.open
            } else {
              faq.open = false;
            }
      
            return faq;
          }))
        }
      
    return (
        
      <>
        <section class = "faqsSection3">
            <div class = "container">
                <div class="row">
                    <div class="col-sm-5">
                        <img src={require('../../images/faq.png')} alt="questionImg" />
                    </div>
                    <div class="col-sm-6">
                        <div className="App">
                            <div className="faqs">
                                {faqs.map((faq, i) => (
                                    <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ} />
                                ))}
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary">View More FAQ's</button>

                    </div>
                </div>
            </div>
        </section>
        
      </>
    );
  }
  

