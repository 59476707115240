import React from 'react'
import '../../App.css';
//import { Link } from 'react-router-dom';

export default () => {
    return (
      <>
      
      <section class="bgimage">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h5>Re-inventing Real Estate <br></br>Investments</h5>
              <img src={require('../../images/icons/square-1.svg')} alt="questionImg" width={95}/> 
              <button type="submit" class="btn btn-primary" >Invest Now</button>

            </div>
          </div>
        </div>
      </section>
      
      </>
    );
  }
  

