import React from 'react';
import '../../App.css';
import MainBanner from '../plots-details/MainBanner';
import Footer from '../home/Footer';
import WhyInvest from '../plots-details/WhyInvest';
import VillaVsApartment from '../plots-details/VillaVsApartment';
import MasterPlan from '../plots-details/MasterPlan';
import ProjectDetails from '../plots-details/ProjectDetails';
import Gallery from '../plots-details/Gallery';

export default function Home() {
  return (
    <>
      <MainBanner />
      <WhyInvest />
      <VillaVsApartment />
      <MasterPlan />
      <Gallery />
      <ProjectDetails />
      <Footer />
      
      
      
      
    </>
  );
}

