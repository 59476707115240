
import '../../App.css';
import React from 'react';

export default () => {
    return (
        
      <>
        <section class = "plotsDetailSection2">
            <div class = "container">
                <div class="section-title">
                    <h2>Why Invest</h2>
                </div> 
                <div class="row">
                    <div class="col-sm-5">
                        <img src={require('../../images/pdSection2.png')} alt="questionImg" />
                    </div>
                    <div class="col-sm-6">
                        <div class = "pd-s2-contents">
                            <img src={require('../../images/icons/hub.svg')} alt="questionImg" />
                            <h1>Growth Hub<br></br><span>The project's location offers good prospects for growth with a strong micro-market, good connectivity, and social infrastructure at a price, which is still giving you the flexibility to build and own a villa with prices matching to an apartment in that location!</span></h1>    
                        </div>
                        <div class = "pd-s2-contents">
                            <img src={require('../../images/icons/bus.svg')} alt="questionImg" />
                            <h1>Upcoming Metro Connectivity <br></br><span>The area of Kanakapura where the asset is located has grown over the decade as a busy hub for many production and manufacturing units, with excellent transport facilities.</span></h1>    
                        </div>
                        <div class = "pd-s2-contents">
                            <img src={require('../../images/icons/eye.svg')} alt="questionImg" />
                            <h1>Well-developed Locality <br></br><span>A plotted project in kanakapura main road with amenities and facilities offers an opportunity to embrace the best that life can offer. Now be a part of a well-developed neighborhood with sufficient living experience whether you are looking for an investment or a place to stay; the project gives you the benefit of both.</span></h1>    
                        </div>
                     </div>
                </div>
            </div>
        </section>
        
      </>
    );
  }
  

