import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
      
      <section class="howItWorks">
        <div class="container">
          <div class="section-title">
            <h2>How it works</h2>
          </div> 
          <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="border-box">
                        <div class="box-number"><p>01</p></div>
                        <div class="box-body">
                            <h4 class="box-title">Browse</h4>
                            <span class="box-content">Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                        </div>
                    </div>
                
                </div>
                
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="border-box">
                        <div class="box-number"><p>02</p></div>
                        <div class="box-body">
                            <h4 class="box-title">Invest</h4>
                            <span class="box-content">Find a suitable plot based on your investment plan and needs, and invest in that plot through us.</span>
                        </div>
                    </div>
            
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="border-box">
                        <div class="box-number"><p>03</p></div>
                        <div class="box-body">
                            <h4 class="box-title">Sell</h4>
                            <span class="box-content">Sell your property at any time to get a profitable return for your investment.</span>
                        </div>
                    </div>
            
                </div>
                </div>
            </div>         
        
    </section>
        
        
      </>
    );
  }
  

