import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
      <section class="footer">
       
    
        <div class="container p-3">
            
            <div class="row">
            
            <div class="col-sm-2">
            <h5 class="text-uppercase">Company</h5>

            <ul class="list-unstyled mb-0">
            <li>
                Home
            </li>
            <li>
                Contact
            </li>
            <li>
                Projects
            </li>
            <li>
                Services
            </li>
            </ul>
            </div>
            
            <div class="col-sm-2">
            <h5 class="text-uppercase">Company</h5>

            <ul class="list-unstyled mb-0">
            <li>
                Home
            </li>
            <li>
                Contact
            </li>
            <li>
                Projects
            </li>
            <li>
                Services
            </li>
            </ul>
            </div>
            
            <div class="col-sm-3">
                <h5 class="text-uppercase mb-0">Location of Company</h5>

                
            </div>
            <div class="col-sm-5">
                <div class="footer-form">
                
                
                </div>
                <div class="footer-div">
                    <form>
                        <div class="form-group">
                        <h5>Get a reply from us</h5><br></br><br></br>
                        <input class="form-control" type="text" placeholder="Name :" />  <br></br>
                        <input class="form-control" type="text" placeholder="Mail :" /><br></br>
                        <input class="form-control" type="text" placeholder="Phone :" /><br></br>
                        </div>
                        
                        <button type="submit" class="btn btn-primary">Submit</button>
                </form>
                </div>
                
            </div>
            
            </div>
            
        </div>
        

    <div class="copyrights"><div class="text-center p-3" >
    Copyrights Smartplots
</div></div>
    
    
    
</section>
        
      </>
    );
  }
  

