import React from "react";
import Carousel from "react-elastic-carousel";
//import Item from "../home/Item";

export default  () => {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 },
      ];
    return (
        <>
        <section class="carouselSection">
        <div class="section-title">
        <h2>Current Offering</h2>
        
      </div>
          <div className="carousel">
            <Carousel breakPoints={breakPoints}>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-2.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-3.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-4.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-2.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-3.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-4.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
              <div class="item">
                <div class="plotImage">
                  <img src={require('../../images/plot-2.png')} alt="plot2Img" />
                </div>
                <p>Location of the Plot</p>
                <h1>Pre Launch Plot Investment Opportunity</h1>
                <span>Browse through the plot listings created by our expert team, who have conducted rigorous due diligence, market review, and legal title.</span>
                <h2>Know More &#x21d2;</h2>

              </div>
            </Carousel>
          </div>
          </section>
        </>
      );
}