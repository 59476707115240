import React from 'react'
import '../../App.css';
//import { Link } from 'react-router-dom';

export default () => {
    return (
      <>
      
      <section class="aboutBg">
        
      </section>
      
      </>
    );
  }
  

