import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
        <section class = "plotsSection2">
        <div class="container">

          <div class="section-title">
            <h2>Want to sell your plot
            </h2>
          </div>
          <div class="section-form">
             <img src={require('../../images/plotsSection2.png')} alt="questionImg" />
             <div class="row">
                <div class="col-sm-6">
                    <div class="form-div">
                        <form>
                            <div class="form-group">
                                <h5>Submit your details</h5><br></br>
                                <input class="form-control" type="text" placeholder="Name :" />  <br></br>
                                <input class="form-control" type="text" placeholder="Mail :" /><br></br>
                                <input class="form-control" type="text" placeholder="Phone :" /><br></br>
                                <input class="form-control" type="text" placeholder="Location :" /><br></br>
                                <input class="form-control" type="text" placeholder="Description :" /><br></br>
                            </div>
                            
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                
                </div>
                <div class="col-sm-6">
                    <div class = "row">
                        <div class = "col-sm-5">
                            <div class="addImage">
                                <div class="addIcon">
                                    <p>ADD IMG</p>
                                    <img src={require('../../images/icons/plus.svg')} alt="plusIcon" />
                                    
                                </div>
                            </div>
                        </div>
                        <div class = "col-sm-5">
                            <div class="addImage">
                                <div class="addIcon">
                                    <p>ADD IMG</p>
                                    <img src={require('../../images/icons/plus.svg')} alt="plusIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="spacer6"></div>
                    <div class = "row">
                        <div class = "col-sm-5">
                            <div class="addImage">
                                <div class="addIcon">
                                    <p>ADD IMG</p>
                                    <img src={require('../../images/icons/plus.svg')} alt="plusIcon" />
                                </div>
                            </div>
                        </div>
                        <div class = "col-sm-5">
                            <div class="addImage">
                                <div class="addIcon">
                                    <p>ADD IMG</p>
                                    <img src={require('../../images/icons/plus.svg')} alt="plusIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>


        </div>
        

          
          
          
          
        </div>
        </section>
        
      </>
    );
  }
  

