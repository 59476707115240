import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Blog from './components/pages/Blog';
import Plots from './components/pages/Plots';
import AboutUs from './components/pages/AboutUs';
import SubmitPlot from './components/pages/SubmitPlot';
import PlotDetails from './components/pages/PlotDetails';


function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/blog' component={Blog} />
        <Route path='/plots' component={Plots} />
        <Route path='/about-us' component={AboutUs} />
        <Route path='/submit-plot' component={SubmitPlot} />
        <Route path='/plot-details' component={PlotDetails} />
      </Switch>
    </Router>
  );
}

export default App;
