import React from 'react';
import '../../App.css';
import Faq from '../plots/Faq';
import ListOfPlots from '../plots/ListOfPlots';
import WantToSellYourPlot from '../plots/WantToSellYourPlot';
import Footer from '../home/Footer';


export default function Plots() {
  return (
    <>
      
      <ListOfPlots />
      <WantToSellYourPlot />
      <Faq />
      <Footer />
      
    </>
  );
}
