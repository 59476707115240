import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
      
      <section class="newsletter">
        <div class="newsletter-container">

        </div>
        <div class="section-title">
            <h2>Subscribe to Our Newsletter
            </h2>
            <p>
            Our newsletter is the best way to keep up with the new plot listings, articles and more.
            </p>
        </div>
        <div class="formDiv">
          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Email address</label>
              <input type="email" class="form-control form-control-lg" id="exampleInputEmail1" aria-describedby="emailHelp"  />
            </div>
            
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
    </section>
        
        
      </>
    );
  }
  

