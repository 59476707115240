import React from 'react'
import '../../App.css';
//import {questionImg} from '../../images/question.svg';

export default () => {
    return (
      <>
      
      <section class="whySmart">
        <div class="container">
            <div class="section-title">
                <h2>Why Smart Plot
                    <img src={require('../../images/question-1.svg')} alt="questionImg" /> 
                </h2>
                <p>We enable investing powered by data, at affordable thresholds, with end-to-end services</p>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="card border-light">
                        
                    </div>
                    
                        <div class="card-body">
                        <h4 class="card-title"><img src={require('../../images/icons/icon-1.svg')} alt="questionImg" /></h4>
                            <h6 class="card-subtitle">Diversified Portfolio</h6>
                            
                        </div>
                        
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="card border-light">
                        
                        
                    </div>
                    <div class="card-body">
                        <h4 class="card-title"><img src={require('../../images/icons/icon-2.svg')} alt="questionImg" /></h4>
                            <h6 class="card-subtitle">Safe & Secure</h6>
                        </div>
                </div>
            </div>
            <div class="row">    
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="card border-light">
                    
                        
                    </div>
                    <div class="card-body">
                        <h4 class="card-title"><img src={require('../../images/icons/icon-3.svg')} alt="questionImg" /></h4>
                            <h6 class="card-subtitle">Data-driven Insights</h6>
                        </div>
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="card border-light">
                    
                        
                    </div>
                    <div class="card-body">
                        <h4 class="card-title"><img src={require('../../images/icons/icon-4.svg')} alt="questionImg" /></h4>
                            <h6 class="card-subtitle">Transparency</h6>
                        </div>
                </div>
            </div>    
                

                

            </div>
        
            
    </section>
        
        
      </>
    );
  }
  

