import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
      
      <section class="whyInvest">
        <div class="container">

          <div class="section-title">
            <h2>Why Investing in Land
            <img src={require('../../images/question.svg')} alt="questionImg" /> 
            </h2>
            <p>
              An enduring asset with steady cash flow and protection from an unstable market.
            </p>
          </div>
          <div class="container">
            <div class="line"></div>
            <div class="row">    
                <div class="col-12 col-sm-6 col-lg-4">
                    <h4 class="card-state"><img src={require('../../images/icons/icon-5.svg')} alt="questionImg" /></h4>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                  <h4 class="card-state"><img src={require('../../images/icons/icon-5.svg')} alt="questionImg" /></h4>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                  <h4 class="card-state"><img src={require('../../images/icons/icon-5.svg')} alt="questionImg" /></h4>
                </div>
              </div>
          </div>
        </div>
    </section>
        
        
      </>
    );
  }
  

