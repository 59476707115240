import React from 'react'
import '../../App.css';
import { useHistory } from "react-router-dom";




export default () => {
    const history = useHistory();

    function handleClick() {
        history.push("/plot-details");
    }

    return (
        
      <>
        <section class = "plots">
        <div class="container">

          <div class="section-title">
            <h2>List of Plots
            </h2>
          </div>
        

          <div class="listOfPlots">
            <div class = "row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>   
                </div>
            </div>
            <div class="spacer5"></div>

            <div class = "row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>   
                </div>
            </div>
            <div class="spacer5"></div>
            <div class = "row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-4">
                    <div class="plotsBox">
                        <div class ="plotsImage">
                            <div class = "plotsLocation">
                                <img src={require('../../images/icons/locationIcon.svg')} alt="questionImg" /> <span>Location</span>
                            </div>
                            <p>Pre Launch Plot Investment Opportunity</p>
                        </div>
                        <button type="submit" class="btn btn-primary" onClick={handleClick}>View Details</button>
                    </div>   
                </div>
            </div>
          
          
            
            
            </div>
          
          
          
        </div>
        </section>
        
      </>
    );
  }
  

