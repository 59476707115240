import React from 'react'
import '../../App.css';

export default () => {
    return (
      <>
      
        <section class="ourApproach">
            <div class="container">
                <div class="section-title">
                    <h2>Our approach in listing a plot
                        <img src={require('../../images/checklist.svg')} alt="checklistImg" /> 
                    </h2>
                    <p>
                        Each property listed on the platform will have a defined investment opportunity
                    </p>
                </div>
                <div class="container">
                    <div class="row">    
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="box">
                                <p>Identifying a property and get our client a pre-negotiated price.</p>
                            </div>
                            <div class="box-top">
                                <p>Buy</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="box">
                                <p>Hold the plot till the growth triggers are visible.</p>
                            </div>
                            <div class="box-top">
                                <p>Hold</p>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-4">
                            <div class="box">
                                <p>Sell the plot or build a villa and sell.</p>
                            </div>
                            <div class="box-top">
                                <p>Build/Sell</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gallery">
            <span>Plots are listed based on the following criteria</span>
                <div class="row"> 
                    <div class="column-1">
                    <div class="col-lg-4 col-md-12 mb-4">
                        <div class="galleryImg">
                            <img src={require('../../images/g-1.png')} alt="questionImg" width={200}/>
                        </div>
                        
                        
                    </div>
                    </div>                 
                    
                    <div class="col-lg-2 col-md-12 mb-4">
                        <div class="galleryImg">
                            <img src={require('../../images/land.png')} alt="questionImg" />
                        </div>
                        <div class="label">Land</div>    
                    </div>
                    <div class="col-lg-2 col-md-12 mb-4">
                        <div class="galleryImg">
                            <img src={require('../../images/location.png')} alt="questionImg" />
                        </div>
                        <div class="label">Location</div>
                    </div>
                    <div class="col-lg-2 col-md-12 mb-4">
                        <div class="galleryImg">
                            <img src={require('../../images/infrastructure.png')} alt="questionImg" />
                        </div>
                        <div class="label">Infrastructure</div>
                    </div>
                    <div class="col-lg-2 col-md-12 mb-4">
                    
                        <div class="galleryImg">
                            <img src={require('../../images/price.png')} alt="questionImg" />
                        </div>
                        <div class="label">Price</div>
                    </div>
                       
                </div>
            </div>
        </section>
        
        
      </>
    );
  }
  

