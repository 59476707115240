
import '../../App.css';
import React from 'react';

export default () => {
    return (
        
      <>
        <section class = "plotsDetailSection5">
            <div class = "row">
                <div class = "col-sm-6">
                    <div class = "container">
                        <div class="section-title">
                            <h2>Location
                                <div class="locationIcon">
                                    <img src={require('../../images/icons/location.svg')} alt="questionImg" /> 
                                </div>            
                            </h2>
                            <span>Kanakapura Road is a quintessential old charm locality with abundant greenery and an underground water table. The location has some prominent schools and is known for its connectivity via ORR and Nice Road.<br></br><br></br>The upcoming metro station till Anganapura and the road widening of NH 207 will further enhance the connectivity. The location has many existing projects for comparison purposes.</span>
                        </div> 

                        <div class="section-title">
                            <h1>Project Details
                                <div class="bulbIcon">
                                    <img src={require('../../images/icons/bulb.svg')} alt="questionImg" /> 
                                </div>            
                            </h1>
                        </div> 
                        <div class="row">                    
                            <div class="col-sm-6">
                                <div class="projectDetails">
                                
                                    <table class="table">
        
                                        <tbody>
                                            <tr>
                                            <th scope="row"></th>
                                            <td>Land Area</td>
                                            <td></td>
                                            <td>12 Acres</td>
                                            </tr>
                                            <tr>
                                            <th scope="row"></th>
                                            <td>Plot Sizes</td>
                                            <td></td>
                                            <td>30*40, 30*50, 40*60</td>
                                            </tr>
                                            <tr>
                                            <th scope="row"></th>
                                            <td>Investment Ticket Size</td>
                                            <td></td>
                                            <td>Rs. 35 Lakhs onward</td>
                                            </tr>
                                            <tr>
                                            <th scope="row"></th>
                                            <td colspan="3">Amenities<br></br>Clubhouse, Garden, Basketball court, Tennis court, Swimming Pool.</td>
                                            </tr>
                                            <tr>
                                            <th scope="row"></th>
                                            <td colspan="3">Layout basic features<br></br>Street Lights, Road Network, Paved Pathway, Sanitary Network, Storm Drain Network, Water Network, Electrical System, Communication Network.</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                
                                
                            </div>
                            
                        </div>
                    
                    </div>
                </div>
                <div class = "col-sm-6">
                    <div class = "mapImage">
                    
                        <img src={require('../../images/locationSide.png')} alt="questionImg" />
                        <img src={require('../../images/map.png')} alt="questionImg" />
                    </div>
                </div>

            </div>
        </section>
        
      </>
    );
  }
  

